* {
  font-family: 'Orbitron', sans-serif;
}

.starter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 10vw;
  padding: 20px;
  box-sizing: border-box;
  background: #d7385e;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 10;
}

.logo {
  display: block;
  height: 100px;
  margin-bottom: 50px;
  box-sizing: border-box;
  padding: 10px 0;
}

.overlay {
  position: fixed;
  top: 5px;
  right: 10px;
  width: 50px;
  height: 50px;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: left;
  cursor: pointer;
}

.overlay i {
  color: white;
  font-size: 40px;
}
